import React from "react"
import Layout from "../../components/layout"
import ProductPageComponents from "../../components/product/product-page-components"
import { graphql } from "gatsby"
import SEO from "../../components/seo"

const ProductTemplate = ({ data, location }) => {
  const { product_name } = data.sanityProduct
  return (
    <Layout>
      <SEO title={product_name} url={location.pathname} />
      <ProductPageComponents data={data} />
    </Layout>
  )
}

export default ProductTemplate

export const query = graphql`
  query($_id: String) {
    sanityProduct(_id: { eq: $_id }) {
      _id
      _type
      slug {
        current
      }
      product_intro_text
      product_meta_data {
        refrigerate
        product_related_product {
          slug {
            current
          }
          product_collection {
            slug {
              current
            }
          }
          product_label {
            label_title
            label_color {
              section_background
            }
          }
          product_disabled
          product_name
          product_image {
            asset {
              fluid {
                ...GatsbySanityImageFluid
              }
            }
          }
        }
      }
      allergens
      ingredients
      informative_label {
        value
        key
      }
      product_variants {
        variants {
          _key
          variant_info_price
          variant_info_value
        }
        variant_type
      }
      product_name
      _rawProductDescription
      _rawProductHowToServe
      product_preview_color {
        section_background
      }
      product_image {
        asset {
          fluid {
            ...GatsbySanityImageFluid
          }
        }
      }
      image_rotate
      product_disabled
      product_label {
        label_title
        label_color {
          section_background
        }
      }
      product_flavor_profile {
        score {
          title_ref {
            title
          }
          score
        }
      }
      product_collection {
        collection_title
        slug {
          current
        }
      }
      product_article_teaser {
        title
        intro_text
        references {
          ... on SanityArticle {
            _id
            slug {
              current
            }
            title: article_title
            meta: article_meta_data {
              article_type_ref {
                article_type_title
              }
            }
            _type
            image: article_image {
              asset {
                fluid {
                  ...GatsbySanityImageFluid
                }
              }
            }
          }
          ... on SanityRecipe {
            _id
            slug {
              current
            }
            _type
            title: recipe_title
            meta: recipe_meta_data {
              article_type_ref {
                article_type_title
              }
            }
            image: recipe_image_image {
              video
              image {
                asset {
                  fluid {
                    ...GatsbySanityImageFluid
                  }
                }
              }
            }
          }
        }
      }
    }
  }
`
