import React from "react"
import { Link } from "gatsby"

const BreadCrumb = ({ section, title, space }) => (
  <>
    {space ? <br /> : null}
    <span style={{ color: "rgba(0, 0, 0, 0.6)" }}>YOU ARE HERE:</span>
    <span style={{ lineHeight: "1.88" }}>
      <br /> <Link to="/">Home</Link> {">"}
      <br /> {section} {">"}
      <br /> {title}
    </span>
  </>
)

export default BreadCrumb
